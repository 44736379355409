import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import CategoryTag from "../CategoryTag"

const Post = ({ excerpt, frontmatter, fields }) => {
  const { title, date, category: categoryTags } = frontmatter
  const { slug } = fields

  return (
    <Wrapper>
      <Article>
        <Link to={"/posts" + slug}>
          <h5>{title}</h5>
        </Link>
        <div className="underline"></div>
        <p>{excerpt}</p>
      </Article>
      <Footer>
        <span>{date}</span>
        <div>
          {categoryTags
            ? categoryTags.map((tag, index) => {
                return (
                  <span key={index}>
                    <CategoryTag category={tag} />
                  </span>
                )
              })
            : ""}
        </div>
      </Footer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 6rem;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`
const Article = styled.article`
  h5 {
    margin: 0;
    margin-bottom: 1.25rem;
  }
  .underline {
    width: 5rem;
    height: 1px;
    background: var(--clr-grey-8);
    margin-left: 0;
    margin-bottom: 1.75rem;
  }
  p {
    line-height: 1.5;
  }
`

export default Post
