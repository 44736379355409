import React from "react"
import Post from "./Post"
import styled from "@emotion/styled"

import Banner from "../Banner"
import Pagination from "../PaginationComponent"

const Posts = ({ posts, pageContext }) => {
  return (
    <Wrapper>
      <div className="posts-center">
        <article>
          {posts.map(item => {
            const post = item.node
            return <Post key={post.id} {...post} />
          })}
          <div className="pagination">
          <Pagination pageContext={pageContext} />
          </div>
        </article>
        <article>
          <Banner />
        </article>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 85vw;
  max-width: var(--maxWidth-wrapper);
  margin: 0 auto;
  margin-bottom: 4rem;
  .pagination {
    margin-top: 6rem;
  }
  .posts-center {
    display: grid;
    row-gap: 5rem;
  }

  @media (min-width: 1170px) {
    .posts-center {
      display: grid;
      grid-template-columns: 1fr 150px;
      column-gap: 10rem;
    }
  }
`

export default Posts
